import React, { useEffect, useState } from 'react';
import { Amplify, Storage } from 'aws-amplify';
import aws_config from '../../modified_aws-export';
import { Col, Card, Button, Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { handleOemMatch, matchActions } from "../../api/backend";
import { Match, Metadata } from './ExtractedImages';
import { useExtractedImagesState } from '../../store';
import {  copyFilenameToClipboard } from '../../utils/manualUtils'


Amplify.configure(aws_config);

interface DisplayMatchedImagesProps {
    oemImageName: string;
    matches: Match[];
}

const DisplayMatchedImages: React.FC<DisplayMatchedImagesProps> = ({ oemImageName, matches }) => {
    const [images, setImages] = useState<string[]>([]);
    const [checked, setChecked] = useState<boolean[]>(new Array(matches.length).fill(false));
    const {selectedImages, addToselectedImages, removeFromselectedImages } = useExtractedImagesState()
    const { t } = useTranslation();

    useEffect(() => {
        const fetchImages = async () => {
            const getMatchedImage = async (fileName: string) => {
                Storage.configure({
                    aws_user_files_s3_bucket_region: aws_config.backend.region,
                    aws_user_files_s3_bucket: aws_config.backend.bucket_name
                });
                fileName = fileName.includes('OEM') ? fileName.replace('OEM', 'TA') : fileName;
                return await Storage.get(`processed_images/${fileName}.png`, {
                    level: 'public',
                    customPrefix: { public: '' },
                    validateObjectExistence: true
                });
            };

            const fetchedImages = await Promise.all(matches.map(async (match) => getMatchedImage(match.metadata.FileName)));
            setImages(fetchedImages);
        };

        fetchImages();
    }, [matches]);

    const matchOemToSingleClick = (metadata: Metadata, oemImageName: string) => {
        if (window.confirm(t("MatchFunctionality.MatchConfirmText"))) {
            handleOemMatch(matchActions.match, metadata.FileName, t, oemImageName, JSON.stringify(metadata));
        }
    };

    const handleCheck = (filename: string, index: number) => {
        const newChecked = [...checked];
        newChecked[index] = true;
        setChecked(newChecked);

        const baseFilename = filename.split('.')[0]; // Extract the part before the dot
        addToselectedImages(baseFilename);
    };

    const handleUncheck = (filename: string, index: number) => {
        const newChecked = [...checked];
        newChecked[index] = false;
        setChecked(newChecked);

        const baseFilename = filename.split('.')[0]; // Extract the part before the dot
        removeFromselectedImages(baseFilename);
    };
    

    const handleCheckboxChange = (filename: string, index: number) => {
        if (checked[index]) {
            handleUncheck(filename, index);
        } else {
            handleCheck(filename, index);
        }
    };

    const onDoubleClick = (manualId: string) => {
        copyFilenameToClipboard(manualId,t("SpecialTool.CopyIdClipboardMessage").replace("{}", manualId))
    }

    return (
        <>
            {matches.map(({ metadata }, index) => {
                const { FileName, matchedBy } = metadata;
                const img = images[index];
                const isOEM = FileName.includes('OEM');
                const isSingle = FileName.includes('Single');
                const displayFileName = isOEM ? FileName.replace('OEM', 'TA') : FileName;

                return (
                    <Col md={4} key={index}>
                        <Card className="mb-3 h-100">
                            <div  className="d-flex justify-content-end me-1">
                            <Form.Check
                                type="checkbox"
                                checked={checked[index]}
                                onChange={() => handleCheckboxChange(FileName,index)}
                                className="custom-checkbox"
                            />
                            </div>
                            
                            <Card.Img variant="top" src={img} alt={`Matched ${index}`} style={{ width: '100%', height: 'auto' }} />
                            <Card.Body className="d-flex flex-column align-items-center">
                                <Card.Text className="text-center" onDoubleClick={() => onDoubleClick(displayFileName)}>
                                    {displayFileName}
                                </Card.Text>
                                {matchedBy === "ImageMatcherTool" ? (
                                    <span
                                        className="badge bg-warning text-dark"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title={t("MatchFunctionality.MatchedWithTool")}
                                        // style={{ position: "relative", left: "1em", top: "-20px", width: "65px", float: "inline-start" }}
                                    >
                                        Matched
                                    </span>
                                ) : (
                                    isSingle && (
                                        <Button
                                            className="mt-1"
                                            variant="outline-secondary"
                                            size="sm"
                                            onClick={() => matchOemToSingleClick(metadata, oemImageName)}
                                        >
                                            {t("Button.match")}
                                        </Button>
                                    )
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                );
            })}
        </>
    );
};

export default DisplayMatchedImages;
