import React, { useEffect, useState } from "react";
import { Amplify, Storage } from "aws-amplify";
import aws_config from "../../modified_aws-export";
import DisplayMatchedImages from "./DisplayMatchedImages";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  Button,
  ListGroup,
  Spinner,
  OverlayTrigger,
} from "react-bootstrap";
import "./PdfImageExtractor.scss";
import { useExtractedImagesState } from "../../store";
import {
  ImageManual,
  handleManualClick,
  copyFilenameToClipboard,
} from "../../utils/manualUtils";
import { useTranslation } from "react-i18next";
import { pineconeQuery } from "../../api/backend";
import {
  useConfigState,
  useResultsState,
  usePdfUploadState,
} from "../../store";
import { useRecordEvent } from "aws-rum-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faThumbsUp as solidThumbsUp,
  faThumbsDown as solidThumbsDown,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faThumbsUp as regularThumbsUp,
  faThumbsDown as regularThumbsDown,
} from "@fortawesome/free-regular-svg-icons";
import { handleSubmitImageFeedbackMutation } from "../../api/backend";
import { toast } from "react-toastify";
import Tooltip from "react-bootstrap/Tooltip";

Amplify.configure(aws_config);

export interface Metadata {
  FileName: string;
  SubGroupID: string;
  FileExtension: string;
  namespace: string;
  Manufacturer: string;
  ItemGraphicID: string;
  matchedBy: string;
}

export interface Match {
  id: string;
  score: number;
  metadata: Metadata;
}

export interface Image {
  matches: Match[];
  usage: {
    read_units: number;
  };
  is_useful: boolean | null;
  feedback_provided: boolean;
}

interface ExtractedImagesProps {
  imageManuals: ImageManual[];
}

export interface DropFile {
  fileobject: File;
  preview: string;
  id: string | null;
  result: any | null;
}

const ExtractedImages: React.FC<ExtractedImagesProps> = ({ imageManuals }) => {
  const [parentImages, setParentImages] = useState<
    {
      parentImage: string;
      parentKeyName: string;
      matches: Match[];
      parentFile: DropFile;
      isUseful: boolean | null;
      feedbackProvided: boolean;
    }[]
  >([]);
  const { resetSelectedImages, displayImageManuals, setDisplayImageManuals } =
    useExtractedImagesState();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { top_k } = useConfigState();
  const { setResults } = useResultsState();
  const { extractionFinished, extractedImages } = usePdfUploadState();
  const recordEvent = useRecordEvent();
  const [isManualFeedbackProvided, setIsManualFeedbackProvided] =
    useState(false);

  useEffect(() => {
    resetSelectedImages();
    const fetchImages = async () => {
      const getImage = async (key: string) => {
        Storage.configure({
          aws_user_files_s3_bucket_region:
            aws_config.aws_user_files_s3_bucket_region,
          aws_user_files_s3_bucket: aws_config.aws_user_files_s3_bucket,
        });
        let img = await Storage.get(`${key}`, {
          level: "public",
          customPrefix: { public: "" },
          validateObjectExistence: true,
        });
        return img;
      };

      const imageData = await Promise.all(
        extractedImages.map(async (element: Record<string, Image>) => {
          const keys = Object.keys(element);
          const parentKey = keys[0];
          const img = await getImage(parentKey);
          const response = await fetch(img);
          const blob = await response.blob();
          const id = parentKey.split("/").pop()?.split(".")[0] || null;
          var filename = "image_similarity_search/" + id + ".png";
          const new_uploadfile = new File([blob], filename, {
            type: "image/png",
          });
          const previewUrl = URL.createObjectURL(new_uploadfile);

          return {
            parentImage: img,
            parentFile: {
              fileobject: new_uploadfile,
              preview: previewUrl,
              id: id,
              result: null,
            },
            parentKeyName: parentKey,
            matches: element[parentKey].matches,
            isUseful: element[parentKey].is_useful,
            feedbackProvided: element[parentKey].feedback_provided,
          };
        })
      );

      setParentImages(imageData);
    };
    fetchImages();
  }, [extractedImages]);

  useEffect(() => {
    setIsManualFeedbackProvided(false);
  }, [displayImageManuals]);

  const handleCloseModal = () => {
    setDisplayImageManuals(false);
  };

  const handleClickFeedbackButtons = (
    isFound: boolean,
    manuals: ImageManual[]
  ) => {
    recordEvent("ManualFeedbackButtonsClicked", {
      isFound: isFound,
      manuals: manuals,
    });
    setIsManualFeedbackProvided(true);
    toast("Submitted!", { type: "success" });
  };

  const onHandleManualClick = async (manual: ImageManual) => {
    handleManualClick(manual.manualId, setLoading, t);
  };

  const onDoubleClick = (manualId: string) => {
    copyFilenameToClipboard(
      manualId,
      t("SpecialTool.CopyIdClipboardMessage").replace("{}", manualId)
    );
  };

  const createDropFileObject = async (fileName: string, index: number) => {
    const dropfile = parentImages[index].parentFile;
    await pineconeQuery(
      dropfile,
      aws_config.aws_user_files_s3_bucket,
      dropfile.fileobject?.name as string,
      Number(top_k),
      setResults,
      recordEvent,
      JSON.stringify({ namespace: { $in: ["OEM", "Single"] } })
    );
    window.open(
      `/image-matcher/results/${dropfile.id}?openInNewTab=true`,
      "_blank"
    );
  };

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("ExtractedImages.FeebackToolTipText")}
    </Tooltip>
  );

  const handleFeedback = async (isUseful: boolean, id: any) => {
    setLoading(true);
    const index = parentImages.findIndex((image) => image.parentFile.id === id);
    if (index !== -1) {
      const isSuccess = await handleSubmitImageFeedbackMutation(
        parentImages[index].parentFile.id,
        aws_config.aws_user_files_s3_bucket,
        parentImages[index].parentKeyName,
        isUseful
      );

      if (isSuccess) {
        setParentImages((prevImages) => {
          const updatedImages = [...prevImages];
          updatedImages[index].isUseful = isUseful;
          updatedImages[index].feedbackProvided = true;

          // Sort images by rating after updating
          return updatedImages.sort((a, b) => {
            if (a.isUseful === b.isUseful) return 0; // If both are the same, maintain order
            if (a.isUseful === true) return -1; // Move true to the front
            if (b.isUseful === true) return 1; // Move true to the front
            if (a.isUseful === null) return -1; // Move null before false
            if (b.isUseful === null) return 1; // Move null before false
            return 0; // If both are false
          });
        });

        toast("Rating Submitted!", { type: "success" });
      } else {
        toast("Rating Submission Failed!", { type: "error" });
      }
    }
    setLoading(false);
  };

  if (!extractionFinished) {
    return (
      <Container
        className="w-80 d-flex justify-content-center align-items-start"
        style={{ paddingTop: "20vh" }}
      >
        <Spinner animation="border" role="status" className="me-3">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <span>Images are still being extracted...</span>
      </Container>
    );
  }
  return (
    <Container className="w-80">
      {loading && (
        <div className="loading-overlay">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      {parentImages.length > 0 ? (
        parentImages.map((imageSet, index) => (
          <Row key={imageSet.parentKeyName} className="mb-4">
            <Col md={4} className="d-flex align-items-stretch">
              <Card
                className="w-100 h-100 card-color"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#272838",
                  color: "#f8f9fa",
                }}
              >
                <Card.Body
                  style={{ flex: 1, display: "flex", flexDirection: "column" }}
                >
                  <Row
                    className="d-flex justify-content-between"
                    style={{ flex: 1 }}
                  >
                    <Col
                      md={12}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Card.Title>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="flex-grow-1 text-center">
                            {t("ExtractedImages.PDFImage").replace(
                              "{}",
                              (index + 1).toString()
                            )}
                          </div>
                          <Button
                            className="me-2 text-align-center"
                            variant="secondary"
                            style={{
                              width: "0.6rem",
                              height: "auto",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() =>
                              createDropFileObject(imageSet.parentImage, index)
                            }
                          >
                            <FontAwesomeIcon icon={faExternalLinkAlt} />{" "}
                            {/* Icon for opening in a new tab */}
                          </Button>
                        </div>
                      </Card.Title>

                      <Card
                        style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Card.Img
                          src={imageSet.parentImage}
                          alt={`Parent ${index}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
                <div className="d-flex justify-content-center align-items-center ">
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="info-icon mb-1 me-2"
                    />
                  </OverlayTrigger>
                  <Button
                    className="me-2 text-align-center"
                    variant="secondary"
                    style={{
                      height: "auto",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => handleFeedback(true, imageSet.parentFile.id)}
                  >
                    <FontAwesomeIcon
                      icon={
                        imageSet.isUseful === true
                          ? solidThumbsUp
                          : regularThumbsUp
                      }
                      size="lg"
                    />
                  </Button>
                  <Button
                    className="me-2 text-align-center"
                    variant="secondary"
                    style={{
                      height: "auto",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() =>
                      handleFeedback(false, imageSet.parentFile.id)
                    }
                  >
                    <FontAwesomeIcon
                      icon={
                        imageSet.isUseful === false
                          ? solidThumbsDown
                          : regularThumbsDown
                      }
                      size="lg"
                    />
                  </Button>
                </div>
              </Card>
            </Col>
            <Col md={8} className="d-flex align-items-stretch">
              <Card
                className="h-100"
                style={{ backgroundColor: "#272838", color: "#f8f9fa" }}
              >
                <Card.Body>
                  <Card.Title>{t("ExtractedImages.MatchedImages")}</Card.Title>
                  <Row className="d-flex justify-content-between">
                    <DisplayMatchedImages
                      oemImageName={imageSet.parentKeyName}
                      matches={imageSet.matches}
                    />
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ))
      ) : (
        <div
          className="d-flex justify-content-center align-items-start"
          style={{ paddingTop: "20vh" }}
        >
          No images found in the uploaded PDF
        </div>
      )}

      <Modal show={displayImageManuals} onHide={handleCloseModal} size="lg">
        {loading && (
          <div className="loading-overlay">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title className="d-flex justify-content-center">
            Manual Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup className="w-100 scrollable-list">
            {imageManuals.map((manual, index) => (
              <ListGroup.Item key={index} className="manual-item">
                <div className="row">
                  <div className="col-3">
                    <p
                      className="manual-id"
                      onDoubleClick={() => onDoubleClick(manual.manualId)}
                    >
                      {`ID: ${manual.manualId}`}
                    </p>
                  </div>
                  <div className="col-6 text-center mb-0">
                    <h5>{manual.itemMpText}</h5>
                  </div>
                  <div className="col-3"></div>
                </div>

                <div className="row align-items-center">
                  <div className="col-3 text-center"></div>
                  <div className="col-6 text-center">
                    <a href="#" onClick={() => onHandleManualClick(manual)}>
                      <p className="manual-name">
                        {manual.manualName} (
                        {`${manual.imageFileNameCount}/${imageManuals.length} image(s) match(es).`}
                        )
                      </p>
                    </a>
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer className="manual-modal-footer">
          <div className="customer-feedback-buttons">
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip>{t("ExtractedImages.ManualButtonsTooltip")}</Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="info-icon mb-1 me-2"
              />
            </OverlayTrigger>
            <Button
              variant="success"
              disabled={isManualFeedbackProvided}
              onClick={() => handleClickFeedbackButtons(true, imageManuals)}
            >
              {t("ExtractedImages.ManualFoundButton")}
            </Button>
            <Button
              variant="outline-danger"
              disabled={isManualFeedbackProvided}
              onClick={() => handleClickFeedbackButtons(false, imageManuals)}
            >
              {t("ExtractedImages.ManualNotFoundButton")}
            </Button>
          </div>
          <div className="action-buttons">
            <Button variant="secondary" onClick={handleCloseModal}>
              {t("ExtractedImages.BackToImagesButton")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ExtractedImages;
